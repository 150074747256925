.custom-btn {
  border            : none;
  outline           : none;
  -webkit-appearance: none;
  -moz-appearance   : none;
  appearance        : none;
  background-color  : var(--customVar-white, #ffffff);
  border-radius     : 10px;
  font-weight       : 600;
  font-size         : 18px;
  font-family       : var(--customVar-title-font, "Outfit", sans-serif);
  padding           : 14px 30px;
  color             : var(--customVar-black, #121212);
  display           : inline-flex;
  -webkit-box-align : center;
  -ms-flex-align    : center;
  align-items       : center;
  overflow          : hidden;
  position          : relative;
  letter-spacing    : 0.01em;
  z-index           : 2;

  &::before {
    content                : "";
    position               : absolute;
    top                    : -50%;
    left                   : 50%;
    -webkit-transform      : translateX(-50%);
    transform              : translateX(-50%);
    width                  : 150%;
    height                 : 150%;
    border-top-left-radius : 50%;
    border-top-right-radius: 50%;
    background-color       : var(--customVar-white, #ffffff);
    -webkit-transition     : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition             : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

  &::after {
    content                           : "";
    position                          : absolute;
    bottom                            : 100%;
    left                              : 50%;
    -webkit-transform                 : translateX(-50%);
    transform                         : translateX(-50%);
    width                             : 150%;
    height                            : 150%;
    border-bottom-left-radius         : 50%;
    border-bottom-right-radius        : 50%;
    background-color                  : var(--customVar-black, #121212);
    -webkit-transition-duration       : 700ms;
    transition-duration               : 700ms;
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function        : cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

  &:hover {
    color: var(--customVar-white, #ffffff);
  }

  &:hover::before {
    top: 100%;
  }

  &:hover::after {
    bottom: -50%;
  }

  >* {
    position: relative;
    color   : inherit;
    z-index : 2;
  }

  .icon {
    font-size         : 10px;
    color             : inherit;
    margin-left       : 9px;
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;
  }

  &.color-primary {
    color             : var(--customVar-white);
    -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
    box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);

    &:hover {
      color: var(--customVar-black, #121212);
    }

    &:before {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &:after {
      background-color: var(--customVar-white);
    }
  }

  &.color-secondary {
    color: var(--customVar-white, #ffffff);

    &:before {
      background-color: var(--customVar-secondary);
    }
  }

  &.color-transparent-light {
    color           : var(--customVar-white);
    border          : 2px solid var(--customVar-white);
    background-color: transparent;

    &:hover {
      color: var(--customVar-black, #121212);
    }

    &::before {
      background-color: transparent;
    }

    &::after {
      background-color: var(--customVar-white);
    }
  }

  &.color-transparent-dark {
    background: transparent;
    border    : 2px solid #010c65;

    &::before {
      background-color: transparent;
    }
  }

  &.hover-color-light {
    &::after {
      background-color: var(--customVar-white);
    }

    &:hover {
      color: var(--customVar-black, #121212);
    }
  }

  &.hover-color-dark {
    &::after {
      background-color: var(--customVar-black, #121212);
    }

    &:hover {
      color: var(--customVar-white, #ffffff);
    }
  }

  &.hover-color-primary {
    &::after {
      background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);
    }

    &:hover {
      color: var(--customVar-white, #ffffff);
    }
  }

  &.hover-color-secondary {
    &::after {
      background-color: var(--customVar-secondary);
    }

    &:hover {
      color: var(--customVar-white, #ffffff);
    }
  }

  &.text-color-light {
    color: var(--customVar-white, #ffffff);
  }

  &.text-color-dark {
    color: var(--customVar-black, #121212);
  }

  &.align-icon-left {
    flex-direction: row-reverse;

    .icon {
      margin-left : 0px;
      margin-right: 9px;
    }
  }
}