/***********************
*  Banner One
***********************/
.banner {
  background-image   : url(../../images/background/banner.png);
  background-repeat  : no-repeat;
  background-size    : cover;
  background-position: center center;
  padding-top        : 221px;
  padding-bottom     : 30px;

  &.no-image {
    padding-top: 70px;
  }

  .banner__left {
    position  : relative;
    display   : block;
    margin-top: 115px;
  }

  .banner__tagline {
    margin       : 0;
    font-family  : "Inter";
    font-style   : normal;
    font-weight  : 400;
    font-size    : 16px;
    line-height  : 1.78em;
    color        : #ffffff;
    margin-bottom: 4px;
  }

  .banner__sub-title-box {
    position   : relative;
    display    : flex;
    align-items: center;
  }

  .banner__sub-title-icon {
    position   : relative;
    display    : flex;
    align-items: center;

    span {
      font-size: 20px;
      color    : #00c14d;
      position : relative;
      display  : inline-block;
    }
  }

  .banner__sub-title {
    color      : var(--customVar-white);
    margin     : 0;
    margin-left: 10px;
  }

  .banner__title {
    font-weight  : 700;
    font-size    : 40px;
    line-height  : 56px;
    color        : var(--customVar-white);
    margin       : 0;
    margin-top   : 17px;
    margin-bottom: 15px;
  }

  .banner__text {
    color        : var(--customVar-white);
    margin       : 0;
    line-height  : 32px;
    margin-bottom: 30px;

    .highlight {
      font-size  : 18px;
      font-weight: 700;
    }
  }

  .banner__right {
    position: relative;
    display : block;
  }

  .banner__img {
    position: relative;
    display : block;

    img {
      width: 100%;
    }
  }
}

/* XL screen  */
@media (min-width: $breakpoint-xl) and (max-width: calc($breakpoint-xxl - 1px)) {
  .banner {
    .banner__title {
      font-size  : 35px;
      line-height: 45px;
    }
  }
}

/* Medium screen  */
@media (min-width: $breakpoint-lg) and (max-width: calc($breakpoint-xl - 1px)) {
  .banner {
    &.no-image {
      padding-top: 150px;
    }

    .banner__left {
      margin-top: 0;
    }

    .banner__title {
      font-size  : 30px;
      line-height: 35px;
    }
  }
}

/* Tablet Layout: 768px. */
@media (min-width: $breakpoint-md) and (max-width: calc($breakpoint-lg - 1px)) {
  .banner {
    &, &.no-image {
      padding-top: 30px;
    }

    .banner__left {
      margin-top: 0;
    }

    .banner__right {
      margin-top: 50px;
    }

    .banner__title {
      font-size  : 28px;
      line-height: 30px;
    }
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .banner {
    padding-top: 163px;

    &.no-image {
      padding-top: 40px;
    }

    .banner__left {
      margin-top: 0;
    }

    .banner__right {
      margin-top: 50px;
    }

    .banner__title {
      font-size  : 25px;
      line-height: 28px;
    }

    .banner__text br {
      display: none;
    }
  }
}