/***********************
* Language switcher
***********************/

.language-switcher {
  display          : -webkit-inline-box;
  display          : -ms-inline-flexbox;
  display          : inline-flex;
  -webkit-box-align: center;
  -ms-flex-align   : center;
  align-items      : center;
  margin-left      : 5px;

  i {
    color       : $menu-item-color;
    font-size   : $menu-item-icon-size;
    margin-right: 8px;
    font-weight : 400;

    &::after {
      display: none;
    }
  }

  button {
    transition : all $menu-animation-duration ease;
    font-size  : $menu-item-font-size;
    color      : $menu-item-color;
    font-weight: 400;

    >i {
      transition : all $menu-animation-duration ease;
      margin-left: 5px;
    }

    &:hover,
    &:hover>i {
      color: $menu-item-hover-color;
      text-shadow: 0 0 1px currentColor;
    }

    &::after {
      display: none;
    }
  }

  .topbar-dropdown-menu {
    padding-top   : 0;
    padding-bottom: 0;
    margin-top    : 0;
    z-index       : 991;
    border-radius : 0;

    a.dropdown-item {
      color             : $menu-submenu-item-color;
      font-size         : $menu-item-font-size;
      font-weight       : 400;
      padding           : 4px 20px;
      -webkit-transition: all 0.4s ease;
      transition        : all 0.4s ease;
      display           : -webkit-inline-box;
      display           : -ms-inline-flexbox;
      display           : inline-flex;
      -webkit-box-align : center;
      -ms-flex-align    : center;
      align-items       : center;

      &.selected {
        color: $menu-submenu-item-active-color;
      }

      &:not(.selected):hover {
        color     : $menu-submenu-item-hover-color;
        background: $menu-submenu-item-hover-bg;
      }
    }
  }

  .mobile-nav__wrapper & {
    display    : none;
    margin-left: 0;

    .icon-globe {
      font-size   : 16px;
      color       : $menu-item-color;
      margin-right: 15px;
    }

    .dropdown {
      display: inline-block;
    }
  }

}

/* Mobile Layout: 320px. */
@media (min-width: calc($breakpoint-xl)) {
  .language-switcher:before {
    background-color: $menu-item-color;
    content         : "";
    height          : 26px;
    margin-right    : 15px;
    width           : 2px;
  }
}

/* Mobile Layout: 320px. */
@media (max-width: calc($breakpoint-md - 1px)) {
  .language-switcher {
    display: none;

    .mobile-nav__wrapper & {
      display: block;
    }
  }
}