$text-color-primary  : hsl(0, 0%, 40%);
$text-color-secondary: $primary;

// Main menu
$menu-dropdown-min-width    : 200px;
$menu-dropdown-max-width    : 300px;
$menu-dropdown-big-min-width: 200px;
$menu-dropdown-big-max-width: 1100px;

// Chevron Icons
// $chevron-left: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'><polyline points='15 18 9 12 15 6'></polyline></svg>";
$chevron-right: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'><polyline points='9 18 15 12 9 6'></polyline></svg>";
// $chevron-up: "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-up'><polyline points='18 15 12 9 6 15'></polyline></svg>";
$chevron-down : "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'></polyline></svg>";

//-----------//
// Offcanvas //
//-----------//
.offcanvas-body {
  padding: 1rem 0;
}

// .nav-item:has(a) {
//   background-color: $primary;
//   background: linear-gradient(118deg, rgba($primary, 1), rgba($primary, 0.7));
//   box-shadow: 0 0 10px 1px rgb($primary, 0.7);
//   color: $white;
// }





/***********************
* Main Menu
***********************/
.main-menu-container {
  padding-top   : 19px;
  padding-bottom: 19px;
  background    : $menu-bg;
  box-shadow    : $menu-box-shadow;
  font-size     : $menu-item-font-size;
  position      : relative;

  >.container {
    display        : flex;
    justify-content: space-between !important;
    align-items    : center;
  }

  li {
    list-style: none;
  }
}

.main-menu {
  .menu-link {
    text-decoration: none;
  }

  .menu-title {
    text-transform: uppercase;
    font-weight   : 700;
    font-size     : calc(#{$menu-item-font-size} - 4px);
  }

  .menu-item,
  .menu-item-label,
  .menu-link {
    font-size: $menu-item-font-size;
  }

  .menu-item,
  .menu-title,
  .menu-item-label,
  .menu-link {
    display    : flex;
    align-items: center;
    position   : relative;
  }

  .menu-item-label {
    flex-direction: column;
    align-items   : flex-start;
  }

  .menu-item-description {
    font-size : calc(#{$menu-item-font-size} - 4px);
    font-style: italic;
  }

  .menu-item,
  .menu-title,
  .menu-item>.menu-link {
    color: $menu-submenu-item-color;
  }

  .menu-item {
    >* {
      -webkit-transition: all $menu-animation-duration ease;
      transition        : all $menu-animation-duration ease;
    }

    .badge {
      margin   : -0.8rem 0 0 0.5rem;
      font-size: calc(#{$menu-item-font-size} - 5px);
      padding  : calc((#{$menu-item-font-size} - 5px) / 3 - 1px) calc((#{$menu-item-font-size} - 5px) / 2) calc((#{$menu-item-font-size} - 5px) / 3 + 1px);
    }

    &.has-sub {
      display: flex;

      >.menu-link::after {
        font-family    : "Material Design Icons";
        margin-left    : 5px;
        flex-grow      : 1;
        display        : flex;
        justify-content: flex-end;
      }
    }

    &.active {
      >.menu-link {
      }
    }

    &:hover {
      background: $menu-submenu-item-hover-bg;

      >.menu-link {
        color: $menu-submenu-item-hover-color;
      }

      &.active {
        // background: $menu-submenu-item-active-hover-bg;

        >.menu-link {
          color: $menu-submenu-item-active-hover-color;
        }
      }
    }
  }

  .divider {
    opacity         : 0.4;
    margin          : 10px;
    height          : 1px;
    border          : none;
    background-color: $menu-submenu-item-color;
  }

  i,
  svg {
    margin-right: 8px;
  }

  i {
    font-size: $menu-item-icon-size;
  }

  svg {
    width : $menu-item-icon-size;
    height: $menu-item-icon-size;
  }

  .sub-menu {
    animation: growDown $menu-animation-duration ease-in-out forwards;

    >.menu-item,
    >.menu-title {
      padding: 6px 15px;

      // height: 60px;
      // padding: 20px 0;
      // flex-wrap: wrap;
      // align-content: stretch;
      // flex-grow: 1;
      >* {
        flex-grow: 1;
      }
    }


    // margin-top   : 0;

    // padding: var(--ct-dropdown-padding-y) var(--ct-dropdown-padding-x);
    // margin: 0;
    // text-align: left;
    // background-color: var(--ct-dropdown-bg);
    // background-clip: padding-box;
    // border: var(--ct-dropdown-border-width) solid var(--ct-dropdown-border-color);




    // color: #666;
    // max-width: 1100px;
    // min-width: 200px;
    // padding: 50px 40px 40px;
    // animation: growDown .3s ease-in-out forwards;
    // transform-origin: top center;
    // width: auto;
  }

  .big>.sub-menu {
    >.menu-item>.menu-link {

      .menu-item,
      .menu-item-label,
      .menu-link {
        font-size: calc(#{$menu-item-font-size} + 4px);
      }

      >.menu-item,
      >.menu-title {
        padding: 10px 20px;
      }

      .badge {
        font-size: calc(#{$menu-item-font-size} - 1px);
        padding  : calc((#{$menu-item-font-size} - 1px) / 3 - 2px) calc((#{$menu-item-font-size} - 1px) / 2) calc((#{$menu-item-font-size} - 1px) / 3 + 2px);
      }

      .menu-item-description {
        font-size: $menu-item-font-size;
      }

      i,
      svg {
        margin-right: 16px;
      }

      i {
        font-size: calc(#{$menu-item-icon-size} + 8px);
      }

      svg {
        width : calc(#{$menu-item-icon-size} + 8px);
        height: calc(#{$menu-item-icon-size} + 8px);
      }
    }

    .menu-title {
      font-size: calc(#{$menu-item-font-size} + 4px);
    }
  }

  .small>.sub-menu {
    >.menu-item>.menu-link {

      .menu-item,
      .menu-item-label,
      .menu-link {
        font-size: calc(#{$menu-item-font-size} - 2px);
      }

      >.menu-item,
      >.menu-title {
        padding: 6px 6px;
      }

      .badge {
        font-size: calc(#{$menu-item-font-size} - 6px);
        padding  : calc((#{$menu-item-font-size} - 6px) / 3 - 1px) calc((#{$menu-item-font-size} - 1px) / 2) calc((#{$menu-item-font-size} - 6px) / 3 + 1px);
      }

      .menu-item-description {
        font-size: calc(#{$menu-item-font-size} - 6px);
      }

      i,
      svg {
        margin-right: 5px;
      }

      i {
        font-size: calc(#{$menu-item-icon-size} - 2px);
      }

      svg {
        width : calc(#{$menu-item-icon-size} - 2px);
        height: calc(#{$menu-item-icon-size} - 2px);
      }
    }

    .menu-title {
      font-size: calc(#{$menu-item-font-size} - 6px);
    }

    .divider {
      margin: 5px 10px;
    }
  }

  .menu-root {
    padding: 0;
    margin : 0;

    >.menu-item,
    >.menu-title,
    >.menu-item>.menu-link {
      color  : $menu-item-color;
      padding: 5px 15px;
    }

    >.menu-item {
      &.active {
        >.menu-link {}
      }

      &:hover {
        background: $menu-item-hover-bg;

        >.menu-link {
          color: $menu-item-hover-color;
        }

        &.active {
          >.menu-link {}
        }
      }

      &.has-sub {
        >.menu-link::after {}
      }
    }

    >.divider {}

    >.menu-item,
    >.menu-title {
      padding: 0 5px;
    }

    >.menu-item>.sub-menu {}

    >* {
      &:first-child {}

      &:last-child {}
    }
  }


  &.horizontal {
    flex-grow      : 1;
    display        : flex;
    flex-wrap      : nowrap;
    justify-content: flex-end;
    align-items    : center;

    .menu-link {}

    .menu-title {}

    .menu-item,
    .menu-item-label,
    .menu-link {}

    .menu-item,
    .menu-title,
    .menu-item-label,
    .menu-link {}

    .menu-item-label {}

    .menu-item-description {}

    .menu-item,
    .menu-title,
    .menu-item>.menu-link {}

    .menu-item {
      >* {}

      .badge {}

      &.has-sub {
        >.menu-link::after {
          content: "\F0142";
        }
      }

      &.active {
        background: $menu-submenu-item-active-bg;

        >.menu-link {
          color: $menu-submenu-item-active-color;
        }
      }

      &:hover {
        >.menu-link {}

        &.active {
          background: $menu-submenu-item-active-hover-bg;

          >.menu-link {}
        }
      }
    }

    .divider {}

    i,
    svg {}

    i {}

    svg {}

    .sub-menu {
      position: absolute;
      top     : 0;
      left    : 100%;

      min-width    : $menu-submenu-min-width;
      border-radius: 0 $dropdown-border-radius $dropdown-border-radius 0;
      padding      : 0;

      background-color: $menu-submenu-bg;
      border          : $menu-submenu-border;
      box-shadow      : $menu-submenu-box-shadow;

      >.menu-item,
      >.menu-title {
        >* {}
      }
    }

    .big>.sub-menu {
      >.menu-item>.menu-link {

        .menu-item,
        .menu-item-label,
        .menu-link {}

        >.menu-item,
        >.menu-title {}

        .badge {}

        .menu-item-description {}

        i,
        svg {}

        i {}

        svg {}
      }

      .menu-title {}
    }

    .small>.sub-menu {
      >.menu-item>.menu-link {

        .menu-item,
        .menu-item-label,
        .menu-link {}

        >.menu-item,
        >.menu-title {}

        .badge {}

        .menu-item-description {}

        i,
        svg {}

        i {}

        svg {}
      }

      .menu-title {}

      .divider {}
    }

    .sub-menu .sub-menu {
      animation: growRight $menu-animation-duration ease-in-out forwards;
    }

    .has-sub:hover {
      >.sub-menu {}
    }

    .menu-root {
      display        : flex;
      justify-content: flex-end;

      >.menu-item,
      >.menu-title,
      >.menu-item>.menu-link {
        border-radius: 4px;
      }

      >.menu-item {
        &.active {
          background: $menu-item-active-bg;
          box-shadow: $menu-item-active-box-shadow;

          >.menu-link {
            color: $menu-item-active-color;
          }
        }

        &:hover {
          >.menu-link {}

          &.active {
            background: $menu-item-active-hover-bg;

            >.menu-link {
              color: $menu-item-active-hover-color;
            }
          }
        }

        &.has-sub {
          >.menu-link::after {
            content: "\F0140";
          }
        }
      }


      >.divider {
        opacity    : 1;
        margin     : auto 0 auto 15px !important;
        border-left: 2px solid $menu-item-color;
        height     : 26px;
      }

      >.menu-item,
      >.menu-title {}

      >.menu-item>.sub-menu {
        top          : 100%;
        left         : 0;
        border-radius: 0 0 $dropdown-border-radius $dropdown-border-radius;
      }

      >* {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  &.vertical {
    .menu-link {
      width: 100%;
    }

    .menu-title {}

    .menu-item,
    .menu-item-label,
    .menu-link {}

    .menu-item,
    .menu-title,
    .menu-item-label,
    .menu-link {}

    .menu-item-label {}

    .menu-item-description {}

    .menu-item,
    .menu-title,
    .menu-item>.menu-link {}

    .menu-item {
      >* {}

      .badge {}

      &.has-sub {
        flex-direction: column;

        >.menu-link::after {
          content: "\F0142";
        }

        &.open>.menu-link::after {
          content: "\F0140";
        }
      }

      &.active {
        >.menu-link {
          font-weight: bold;
        }
      }

      &:hover {
        >.menu-link {}

        &.active {
          >.menu-link {}
        }
      }
    }

    .divider {}

    i,
    svg {}

    i {}

    svg {}

    .sub-menu {
      width: 100%;
      padding: 0;

      >.menu-item,
      >.menu-title {
        >* {}
      }
    }

    .big>.sub-menu {
      >.menu-item>.menu-link {

        .menu-item,
        .menu-item-label,
        .menu-link {}

        >.menu-item,
        >.menu-title {}

        .badge {}

        .menu-item-description {}

        i,
        svg {}

        i {}

        svg {}
      }

      .menu-title {}
    }

    .small>.sub-menu {
      >.menu-item>.menu-link {

        .menu-item,
        .menu-item-label,
        .menu-link {}

        >.menu-item,
        >.menu-title {}

        .badge {}

        .menu-item-description {}

        i,
        svg {}

        i {}

        svg {}
      }

      .menu-title {}

      .divider {}
    }

    .menu-root {
      margin-top   : 30px;
      margin-bottom: 10px;

      >.menu-item,
      >.menu-title,
      >.menu-item>.menu-link {}

      >.menu-item {
        &.active {
          >.menu-link {}
        }

        &:hover {
          >.menu-link {}

          &.active {
            >.menu-link {}
          }
        }

        &.has-sub {
          >.menu-link::after {}
        }
      }

      >.divider {
        height: 2px;
      }

      >.menu-item,
      >.menu-title {}

      >.menu-item>.sub-menu {}

      >* {
        &:first-child {}

        &:last-child {}
      }
    }













    // .sub-menu {
    //   display: none;
    // }
  }
}

@media (max-width: calc($breakpoint-xl + 1px)) {
  .main-menu.horizontal {
    display: none;
  }
}