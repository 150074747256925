/***********************
* Top offer
***********************/

.top-offer {
  position: relative;
  display : none;

  .top-offer__dismiss {
    position          : absolute;
    top               : 50%;
    right             : 50px;
    -webkit-transform : translateY(-50%);
    transform         : translateY(-50%);
    width             : 30px;
    height            : 30px;
    border            : 2px solid #ced6f2;
    border-radius     : 50%;
    display           : -webkit-box;
    display           : -ms-flexbox;
    display           : flex;
    -webkit-box-align : center;
    -ms-flex-align    : center;
    align-items       : center;
    -webkit-box-pack  : center;
    -ms-flex-pack     : center;
    justify-content   : center;
    cursor            : pointer;
    color             : var(--customVar-black, #121212);
    -webkit-transition: all 500ms ease;
    transition        : all 500ms ease;

    &:hover {
      color       : var(--customVar-base, #ffa065);
      border-color: var(--customVar-base, #ffa065);
    }

    i {
      font-size: 10px;
    }
  }

  .container {
    display          : -webkit-box;
    display          : -ms-flexbox;
    display          : flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    -webkit-box-pack : center;
    -ms-flex-pack    : center;
    justify-content  : center;
    padding-top      : 17.5px;
    padding-bottom   : 17.5px;
  }

  p {
    margin           : 0;
    font-family      : "Inter";
    font-style       : normal;
    font-weight      : 600;
    font-size        : 16px;
    display          : -webkit-inline-box;
    display          : -ms-inline-flexbox;
    display          : inline-flex;
    -webkit-box-align: center;
    -ms-flex-align   : center;
    align-items      : center;
    color            : #121212;

    >i {
      font-size              : 35px;
      background-image       : linear-gradient(93.28deg, #ffa065 -5.32%, #ff5a74 105.23%);
      -webkit-background-clip: text;
      background-clip        : text;
      -webkit-text-fill-color: transparent;
      margin-right           : 30px;
      position               : relative;
      top                    : -7px;

      &:last-child {
        margin-right: 0;
        margin-left : 30px;
        top         : 7px;
      }
    }

    a {
      margin-left       : 10px;
      color             : #1061ff;
      display           : -webkit-inline-box;
      display           : -ms-inline-flexbox;
      display           : inline-flex;
      -webkit-box-align : center;
      -ms-flex-align    : center;
      align-items       : center;
      line-height       : 1em;
      -webkit-transition: all 500ms ease;
      transition        : all 500ms ease;

      &:hover {
        color: var(--customVar-primary, #ff5a74);
      }

      span {
        display       : inline-block;
        padding-bottom: 2px;
        border-bottom : 1px solid currentColor;
      }

      i {
        font-size  : 12px;
        margin-left: 7px;
      }
    }
  }

  .offer-img {
    max-height  : 60px;
    margin-right: 20px;
  }
}

/***********************
* Sale One
***********************/
.sale-one {
  position: absolute;
  top     : 20px;
  left    : 0;
  right   : 0;
  z-index : 2;

  .top-offer__dismiss {
    // position          : absolute;
    // top               : 50%;
    // right             : 50px;
    // -webkit-transform : translateY(-50%);
    // transform         : translateY(-50%);
    // width             : 30px;
    // height            : 30px;
    // border            : 2px solid #ced6f2;
    // border-radius     : 50%;
    // display           : -webkit-box;
    // display           : -ms-flexbox;
    // display           : flex;
    // -webkit-box-align : center;
    // -ms-flex-align    : center;
    // align-items       : center;
    // -webkit-box-pack  : center;
    // -ms-flex-pack     : center;
    // justify-content   : center;
    // cursor            : pointer;
    color                : var(--customVar-white, #ffffff);
    // -webkit-transition: all 500ms ease;
    // transition        : all 500ms ease;

    // &:hover {
    //   color       : var(--customVar-base, #ffa065);
    //   border-color: var(--customVar-base, #ffa065);
    // }

    i {
      font-size: 8px;
    }
  }

  p {
    color: var(--customVar-white);
  }
}


@media (min-width: $breakpoint-lg) {
  .top-offer {
    display: block;
  }
}