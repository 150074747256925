//
// forms.scss
//

// .react-datepicker-wrapper  input[type="date"] {
input[type="date"].hideIcon {
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
  }
}


// Form-control light

.form-control-light {
  background-color: var(--#{$prefix}light) !important;
  border-color: var(--#{$prefix}border-color) !important;
  border-width: $card-border-width;
  border-style: solid;
  border-color: $card-border-color;
}

// Form elements (Color and Range)

input.form-control[type="color"],
input.form-control[type="range"] {
  min-height: 39px;
}

input.uppercase::placeholder {
text-transform: none;
}

// Custom select

.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
      box-shadow: none !important;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
      height: $input-height;
  }
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
      height: $input-height-sm;
  }
}

// Show/Hide Password
.password-eye {
  cursor: pointer;

  &:before {
      font-family: "Material Design Icons";
      content: "\F06D0";
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      vertical-align: middle;
      line-height: 1.2;
      font-size: 16px;
  }
}

.show-password {
  .password-eye {
      &:before {
          content: "\F06D1";
      }
  }
}

.rbt + .invalid-feedback {
display: block;
}

.rbt-aux {
align-items: center;
display: flex;
bottom: 0;
justify-content: center;
pointer-events: none;
position: absolute;
right: 0;
top: 0;
width: 2rem;

.rbt-close {
  margin-top: -0.25rem;
  pointer-events: auto;
  .rbt-close-content {
    color: transparent;
  }
}
}
