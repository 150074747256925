body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size              : 16px;
  line-height            : 2em;
  font-family            : var(--customVar-body-font, "Arial", sans-serif);
  color                  : var(--customVar-text, #666666);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
}

h1 {
  font-family: var(--customVar-title-font, "Outfit", sans-serif);
}

h2 {
  font-family: var(--customVar-title-font, "Outfit", sans-serif);
}

h3 {
  font-family: var(--customVar-title-font, "Outfit", sans-serif);
}

h4 {
  font-family: var(--customVar-title-font, "Outfit", sans-serif);
}

h5 {
  font-family: var(--customVar-title-font, "Outfit", sans-serif);
}

h6 {
  font-family: var(--customVar-title-font, "Outfit", sans-serif);
}

::-webkit-input-placeholder {
  color  : inherit;
  opacity: 1;
}

:-ms-input-placeholder {
  color  : inherit;
  opacity: 1;
}

::-ms-input-placeholder {
  color  : inherit;
  opacity: 1;
}

::placeholder {
  color  : inherit;
  opacity: 1;
}