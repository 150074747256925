:root {
  --customVar-body-font    : "Arial", sans-serif;
  --customVar-title-font   : "Outfit", sans-serif;
  --customVar-base         : #ffa065;
  --customVar-base-rgb     : 255, 160, 101;
  --customVar-primary      : $primary;
  --customVar-primary-rgb  : 255, 90, 116;
  --customVar-secondary    : $secondary;
  --customVar-secondary-rgb: 56, 82, 255;
  --customVar-black        : #121212;
  --customVar-black-rgb    : 18, 18, 18;
  --customVar-dark         : #000932;
  --customVar-dark-rgb     : 0, 9, 50;
  --customVar-gray         : #c2c2c2;
  --customVar-gray-rgb     : 194, 194, 194;
  --customVar-text         : #666666;
  --customVar-text-rgb     : 102, 102, 102;
  --customVar-white        : #ffffff;
  --customVar-white-rgb    : 255, 255, 255;
}

// Menu
$menu-border-radius        : 5px;
$menu-item-icon-size       : 18px;
$menu-item-font-size       : 17px;
// $menu-item-padding-x    : var(--#{$prefix}menu-item-padding-x);
// $menu-item-padding-y    : var(--#{$prefix}menu-item-padding-y);
$menu-animation-duration   : 500ms;
$menu-submenu-min-width    : 200px;


$menu-bg               : var(--customVar-white); // bg-leftbar rename to menu-bg
$menu-box-shadow       : 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
$menu-item-color       : var(--customVar-text);
$menu-item-hover-color : $primary;
$menu-item-hover-bg    : #f8f9fa;

$menu-item-active-color       : var(--customVar-white);
$menu-item-active-bg          : linear-gradient(118deg, #0d6efd, rgba(13, 110, 253, 0.7));
$menu-item-active-box-shadow  : 0px 0px 6px 1px rgba(13, 110, 253, 0.6);
$menu-item-active-hover-bg    : linear-gradient(118deg, rgba(13, 110, 253, 0.7), #0d6efd);
$menu-item-active-hover-color : #dee2e6;

$menu-submenu-bg                      : var(--customVar-white);
$menu-submenu-border                  : 1px solid rgba(0, 0, 0, .15);
$menu-submenu-box-shadow              : 0 .5rem 1rem rgba(0, 0, 0, .15);
$menu-submenu-item-color              : var(--customVar-text);
$menu-submenu-item-active-hover-color : var(--customVar-white);
$menu-submenu-item-active-color       : var(--customVar-white);
$menu-submenu-item-active-bg          : linear-gradient(118deg, #0d6efd, rgba(13, 110, 253, 0.7));
$menu-submenu-item-hover-color        : $primary;
$menu-submenu-item-hover-bg           : #f8f9fa;
$menu-submenu-item-active-hover-color : var(--customVar-white);
$menu-submenu-item-active-hover-bg    : linear-gradient(118deg, rgba(13, 110, 253, 0.7), #0d6efd);



$breakpoint-xs: map-get($grid-breakpoints, xs); // 0
$breakpoint-sm: map-get($grid-breakpoints, sm); // 576px
$breakpoint-md: map-get($grid-breakpoints, md); // 768px
$breakpoint-lg: map-get($grid-breakpoints, lg); // 992px
$breakpoint-xl: map-get($grid-breakpoints, xl); // 1200px
$breakpoint-xxl: map-get($grid-breakpoints, xxl); // 1400px