
@include animationMixin(sticky-header-drop-down, 500ms, 1, ease) {
  from {
    transform: translateY(-120%);
  }

  to {
    transform: translateY(0);
  }
}

@include animationMixin(rotate-me, 24s, infinite, linear) {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@include animationMixin(shine) {
  100% {
    left: 125%;
  }
}


@include animationMixin(float-bob, 7s, infinite, linear) {
  0% {
    -webkit-transform: translateX(-100px);
    transform        : translateX(-100px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform        : translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-100px);
    transform        : translateX(-100px);
  }
}


@include animationMixin(growDown) {
  0% {
    transform-origin: top center;
    transform: scaleY(0);
  }
  80% {
    transform-origin: top center;
    transform: scaleY(1.1);
  }
  100% {
    transform-origin: top center;
    transform: scaleY(1);
  }
}

@include animationMixin(growRight) {
  0% {
    transform-origin: left;
    transform: scaleX(0);
  }
  80% {
    transform-origin: left;
    transform: scaleX(1.1);
  }
  100% {
    transform-origin: left;
    transform: scaleX(1);
  }
}

/*** 
=============================================
    circle Css
=============================================
***/
@include animationMixin(circle) {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width  : 200%;
    height : 200%;
    opacity: 0;
  }
}


/*** 
=============================================
    Zoom In Out Css
=============================================
***/
@include animationMixin(zoominout) {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform        : rotate(0deg) scale(0.7);
    opacity          : 0;
  }

  50% {
    -webkit-transform: rotate(180deg) scale(1);
    transform        : rotate(180deg) scale(1);
    opacity          : 1;
  }

  100% {
    -webkit-transform: rotate(360deg) scale(0.7);
    transform        : rotate(360deg) scale(0.7);
    opacity          : 0;
  }
}


/*** 
=============================================
    Zoom In Out Css
=============================================
***/
@include animationMixin(zoominout-2) {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform        : rotate(0deg) scale(0.7);
    opacity          : .50;
  }

  50% {
    -webkit-transform: rotate(0deg) scale(1);
    transform        : rotate(0deg) scale(1);
    opacity          : .50;
  }

  100% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform        : rotate(0deg) scale(0.7);
    opacity          : .50;
  }
}


@include animationMixin(bgSlideReverse) {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: 100px 0;
  }

  40% {
    background-position: 200px 0;
  }

  60% {
    background-position: 150px 0;
  }

  80% {
    background-position: 100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}


@include animationMixin(bgSlideReverse2) {
  0% {
    background-position: -0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: -0px 0;
  }
}


@include animationMixin(banner-animate, 70s, infinite, linear) {
  0% {
    -webkit-transform: translateX(-1000px);
    transform        : translateX(-1000px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform        : translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-1000px);
    transform        : translateX(-1000px);
  }
}


@include animationMixin(ripple) {
  70% {
    box-shadow: 0 0 0 40px rgba(10, 165, 205, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(10, 165, 205, 0);
  }
}


/*** 
=============================================
    Animation1 Css
=============================================
***/
@include animationMixin(animation1) {

  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform        : rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform        : rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform        : rotate(10deg) translateX(30px);
  }
}


/*** 
=============================================
    Animation2 Css
=============================================
***/
@include animationMixin(animation2) {
  0% {
    -webkit-transform: rotateY(0deg);
    transform        : rotateY(0deg)
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform        : rotateY(360deg)
  }
}


@include animationMixin(bgSlide) {
  0% {
    background-position: 0 0;
  }

  20% {
    background-position: -100px 0;
  }

  40% {
    background-position: -200px 0;
  }

  60% {
    background-position: -150px 0;
  }

  80% {
    background-position: -100px 0;
  }

  100% {
    background-position: 0px 0;
  }
}


@include animationMixin(ribbonRotate) {

  0%,
  100% {
    -webkit-transform: rotate(0);
    transform        : rotate(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(15deg);
    transform        : rotate(15deg);
  }

  50% {
    -webkit-transform: rotate(30deg);
    transform        : rotate(30deg);
  }
}


@include animationMixin(footerFish) {

  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform        : translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(20px) translateY(30px) rotate(25deg);
    transform        : translateX(20px) translateY(30px) rotate(25deg);
  }

  50% {
    -webkit-transform: translateX(40px) translateY(60px) rotate(45deg);
    transform        : translateX(40px) translateY(60px) rotate(45deg);
  }
}


@include animationMixin(contactSwimmer) {

  0%,
  100% {
    -webkit-transform: translateX(0) translateY(0) rotate(0);
    transform        : translateX(0) translateY(0) rotate(0);
  }

  25%,
  75% {
    -webkit-transform: translateX(15px) translateY(20px) rotate(10deg);
    transform        : translateX(15px) translateY(20px) rotate(10deg);
  }

  50% {
    -webkit-transform: translateX(60px) translateY(35px) rotate(15deg);
    transform        : translateX(60px) translateY(35px) rotate(15deg);
  }
}


/*** 
=============================================
    Float Bob Y Animation Css
=============================================
***/
@include animationMixin(float-bob-y, 2s, infinite, linear) {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
  }
}


@include animationMixin(footerTree, 5s, infinite, ease-in) {

  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform        : rotate(0deg) translateX(0);
  }

  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform        : rotate(5deg) translateX(15px);
  }

  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform        : rotate(10deg) translateX(30px);
  }
}


@include animationMixin(service_hexagon_2) {
  0% {
    -webkit-transform: rotateY(0deg);
    transform        : rotateY(0deg)
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform        : rotateY(360deg)
  }
}


@include animationMixin(rotateScale, 20s, infinite, linear) {
  from {
    transform: rotate(0deg) scale(1);
  }

  to {
    transform: rotate(360deg);
  }
}


/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@include animationMixin(bubbleMover) {
  0% {
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    transform       : translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: center center;
  }

  50% {
    transform       : translateY(50px) translateX(100px) rotate(45deg);
    transform-origin: right bottom;
  }

  80% {
    transform       : translateY(30px) translateX(50px) rotate(15deg);
    transform-origin: left top;
  }

  100% {
    transform       : translateY(0px) translateX(0) rotate(0);
    transform-origin: center center;
  }
}


@include animationMixin(shape-mover, 9s infinite, linear) {

  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    transform: perspective(400px) rotate(0deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}


@include animationMixin(shape-mover-2, 9s infinite, linear) {

  0%,
  100% {
    -webkit-transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    transform        : perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    -webkit-transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
    transform        : perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}


@include animationMixin(banner-3-Shake, 1s, infinite, ease-out) {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }

  30% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  60% {
    transform: rotate3d(1, 0, 0, 0deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  100% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
}


@include animationMixin(squareMover) {

  0%,
  100% {
    transform: translate(0, 0) rotate(0);
  }

  20%,
  60% {
    transform: translate(20px, 40px) rotate(180deg);
  }

  30%,
  80% {
    transform: translate(40px, 60px) rotate(0deg);
  }
}


@include animationMixin(treeMove) {

  0%,
  100% {
    transform: rotate(0deg) translateX(0);
  }

  25%,
  75% {
    transform: rotate(5deg) translateX(15px);
  }

  50% {
    transform: rotate(10deg) translateX(30px);
  }
}


/*--------------------------------------------------------------
#     Zoom Fade Css
--------------------------------------------------------------*/
@include animationMixin(zoom-fade, 5s, infinite, linear) {
  0% {
    transform: scale(0.9) translateX(-50%);
  }

  50% {
    transform: scale(1) translateX(-50%);
  }

  100% {
    transform: scale(0.9) translateX(-50%);
  }
}


/*--------------------------------------------------------------
#     Zoom Fade 2 Css
--------------------------------------------------------------*/
@include animationMixin(zoom-fade-2, 8s, infinite, linear) {
  0% {
    transform: scale(0.9) translateX(0) translateY(0);
  }

  50% {
    transform: scale(1) translateX(0) translateY(0);
  }

  100% {
    transform: scale(0.9) translateX(0) translateY(0);
  }
}


/* custom animations */

@include animationMixin(btnIconMove) {
  0% {
    transform: rotate(0deg) translate(0px, 0px);
  }

  50% {
    transform: rotate(20deg) translate(10px, 0px);
  }

  100% {
    transform: rotate(0deg) translate(0px, 0px);
  }
}


@include animationMixin(img-bounce, 5s, infinite, ease-in-out) {
  0% {
    -webkit-transform: translateY(0);
    transform        : translateY(0);
  }

  100% {
    -webkit-transform: translateY(-30px);
    transform        : translateY(-30px);
  }
}


// /** squares **/

@include animationMixin(squares) {
  0% {
    -webkit-transform: scale(1);
    -moz-transform   : scale(1);
    -o-transform     : scale(1);
    transform        : scale(1);
    opacity          : 0
  }

  20% {
    -webkit-transform: scale(1.24);
    -moz-transform   : scale(1.24);
    -o-transform     : scale(1.24);
    transform        : scale(1.24);
    opacity          : 1
  }

  100% {
    -webkit-transform: scale(2.1);
    -moz-transform   : scale(2.1);
    -o-transform     : scale(2.1);
    transform        : scale(2.1);
    opacity          : 0
  }
}


/***
=============================================
    Float Bob X Animation Css
=============================================
***/
@include animationMixin(float-bob-x, 2s, infinite, linear) {
  0% {
    transform: translateX(-30px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(-30px);
  }
}


/*** 
=============================================
    Float Bob Y Animation Css
=============================================
***/
@include animationMixin(float-bob-y-2, 2s, infinite, linear) {
  0% {
    transform: translateY(-20px) translateX(-50%) rotate(0deg);
    ;
  }

  50% {
    transform: translateY(-10px) translateX(-50%) rotate(0deg);
    ;
  }

  100% {
    transform: translateY(-20px) translateX(-50%) rotate(0deg);
    ;
  }
}