@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?egk9ay');
  src:  url('../../fonts/icomoon.eot?egk9ay#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?egk9ay') format('truetype'),
    url('../../fonts/icomoon.woff?egk9ay') format('woff'),
    url('../../fonts/icomoon.svg?egk9ay#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e916";
}
.icon-cart-icon:before {
  content: "\e91c";
}
.icon-icon-check:before {
  content: "\e927";
}
.icon-icon-check-2:before {
  content: "\e928";
}
.icon-icon-error:before {
  content: "\e92a";
}
.icon-icon-minus:before {
  content: "\e92c";
}
.icon-icon-world:before {
  content: "\e930";
}
.icon-ssl-certificate:before {
  content: "\e937";
}
.icon-arrow-right:before {
  content: "\e917";
}
.icon-artistic-icon:before {
  content: "\e918";
}
.icon-awards-icon:before {
  content: "\e919";
}
.icon-backups:before {
  content: "\e91a";
}
.icon-bandwidth:before {
  content: "\e91b";
}
.icon-chat-icon-2:before {
  content: "\e91d";
}
.icon-clock-icon:before {
  content: "\e91e";
}
.icon-cloud-server:before {
  content: "\e91f";
}
.icon-configure-icon:before {
  content: "\e920";
}
.icon-control-panel:before {
  content: "\e921";
}
.icon-cpaner-reseller:before {
  content: "\e922";
}
.icon-element-icon:before {
  content: "\e923";
}
.icon-hosting-app:before {
  content: "\e924";
}
.icon-hosting-icon:before {
  content: "\e925";
}
.icon-icon-calender:before {
  content: "\e926";
}
.icon-icon-close:before {
  content: "\e929";
}
.icon-icon-map:before {
  content: "\e92b";
}
.icon-icon-password:before {
  content: "\e92d";
  color: #666;
}
.icon-icon-plus:before {
  content: "\e92e";
}
.icon-icon-reply:before {
  content: "\e92f";
}
.icon-mail-icon:before {
  content: "\e931";
}
.icon-monitor-icon:before {
  content: "\e932";
}
.icon-quote-icon:before {
  content: "\e933";
}
.icon-search-icon:before {
  content: "\e934";
}
.icon-security:before {
  content: "\e935";
}
.icon-ssd-server:before {
  content: "\e936";
}
.icon-ssl-certificate-2:before {
  content: "\e938";
}
.icon-support-icon:before {
  content: "\e939";
}
.icon-support-icon-2:before {
  content: "\e93a";
}
.icon-tracking-icon:before {
  content: "\e93b";
}
.icon-viewing-icon:before {
  content: "\e93c";
}
.icon-vps-hosting:before {
  content: "\e93d";
}
.icon-whats-app:before {
  content: "\e93e";
}
.icon-star:before {
  content: "\e914";
}
.icon-star-half:before {
  content: "\e915";
}
.icon-password:before {
  content: "\e913";
  color: #666;
}
.icon-close:before {
  content: "\e912";
}
.icon-globe:before {
  content: "\e90f";
}
.icon-Cart:before {
  content: "\e910";
}
.icon-star1:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-envelope:before {
  content: "\e90d";
}
.icon-location:before {
  content: "\e90e";
}
.icon-check-circle:before {
  content: "\e901";
}
.icon-cart:before {
  content: "\e903";
}
.icon-globe1:before {
  content: "\e906";
}
.icon-arrow:before {
  content: "\e900";
}
.icon-chat:before {
  content: "\e902";
}
.icon-close1:before {
  content: "\e904";
}
.icon-envelope1:before {
  content: "\e905";
}
.icon-location1:before {
  content: "\e907";
}
.icon-phone1:before {
  content: "\e908";
}
.icon-phone-1:before {
  content: "\e909";
}
.icon-stars:before {
  content: "\e90a";
}
.icon-whatsapp:before {
  content: "\e90b";
}
