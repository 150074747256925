/***********************
* Scroll to top
***********************/

.scroll-to-top {
  width             : 45px !important;
  height            : 45px !important;
  background        : var(--customVar-primary, #ff5a74) !important;
  z-index           : 99 !important;
  -webkit-transition: all 0.4s ease !important;
  transition        : all 0.4s ease !important;
  border-radius     : 50% !important;

  svg {
    color      : #ffffff;
    font-size  : 18px;
    line-height: 45px;
  }

  &:hover {
    background-color: var(--customVar-base, #ffa065) !important;

    svg {
      color: #fff;
    }
  }
}