.page-wrapper {
  overflow : hidden;
  position : relative;
  margin   : 0 auto;
  width    : 100%;
  height   : 100%;
  min-width: 300px;
  overflow : hidden;
}

.container {
  padding-left : 15px;
  padding-right: 15px;
}

.close-btn {
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
 
  color: var(--customVar-white);
  -webkit-box-shadow: 0px 13px 24px rgba(229, 51, 42, 0.3);
  box-shadow        : 0px 13px 24px rgba(229, 51, 42, 0.3);
  background: linear-gradient(-43.72deg, #ffa065 6.68%, #ff5a74 76.23%);

  -webkit-transition     : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition             : 700ms cubic-bezier(0.52, 1.64, 0.37, 0.66);

  &:hover {
    background: linear-gradient(-43.72deg, #ff5a74 6.68%, #ffa065 76.23%);
  }
}

.bold {
  font-weight: bold;
}

.smallScrollbar {
  // Scrollbar styles
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray-500;
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background: $gray-300;
    border-radius: 30px;
  }
}

.description-one__points {
  position: relative;
  display : block;
  margin  : 0;

  li {
    position: relative;
    display : flex;

    +li {
      margin-top: 21px;
    }

    .icon {
      position        : relative;
      display         : flex;
      align-items     : center;
      justify-content : center;
      max-width       : 50px;
      width           : 100%;
      height          : 50px;
      background-color: #f1f5ff;
      border-radius   : 50%;
      top             : 7px;

      span {
        position : relative;
        display  : inline-block;
        font-size: 16px;
        color    : var(--customVar-secondary);
      }
    }

    .content {
      position   : relative;
      display    : block;
      margin-left: 30px;

      h3 {
        margin     : 0;
        font-weight: 600;
        font-size  : 22px;
        line-height: 32px;
        color      : #121212;
      }

      p {
        margin        : 5px 0 0;
        letter-spacing: -0.015em;
        line-height   : 26px;
      }
    }
  }

  &.compact {
    li {
      +li {
        margin-top: 5px;
      }

      .icon {
        max-width       : 25px;
        height          : 25px;

        span {
          font-size: 10px;
        }
      }

      .content {
        margin-left: 15px;
      }  
    }
  }
}

@media (min-width: $breakpoint-xl) {
  .container {
    max-width: 90%;
  }
}

@media (min-width: $breakpoint-xxl) {
  .container {
    max-width: 1350px;
  }
}
