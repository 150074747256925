@mixin animationMixin( // $class,
  $name,
  $duration: 3s,
  $iteration: infinite,
  $timingFunction: ease-in-out,
  $delay: 0s,
  $direction: normal,
  $fill-mode: none,
  $play-state: running,
) {
  .#{$name} {
    animation-name           : $name;
    animation-duration       : $duration;
    animation-timing-function: $timingFunction;
    animation-delay          : $delay;
    animation-iteration-count: $iteration;
    animation-direction      : $direction;
    animation-fill-mode      : $fill-mode;
    animation-play-state     : $play-state;

    -webkit-animation-name           : $name;
    -webkit-animation-duration       : $duration;
    -webkit-animation-timing-function: $timingFunction;
    -webkit-animation-delay          : $delay;
    -webkit-animation-iteration-count: $iteration;
    -webkit-animation-direction      : $direction;
    -webkit-animation-fill-mode      : $fill-mode;
    -webkit-animation-play-state     : $play-state;

    -moz-animation-name           : $name;
    -moz-animation-duration       : $duration;
    -moz-animation-timing-function: $timingFunction;
    -moz-animation-delay          : $delay;
    -moz-animation-iteration-count: $iteration;
    -moz-animation-direction      : $direction;
    -moz-animation-fill-mode      : $fill-mode;
    -moz-animation-play-state     : $play-state;

    -ms-animation-name           : $name;
    -ms-animation-duration       : $duration;
    -ms-animation-timing-function: $timingFunction;
    -ms-animation-delay          : $delay;
    -ms-animation-iteration-count: $iteration;
    -ms-animation-direction      : $direction;
    -ms-animation-fill-mode      : $fill-mode;
    -ms-animation-play-state     : $play-state;

    -o-animation-name           : $name;
    -o-animation-duration       : $duration;
    -o-animation-timing-function: $timingFunction;
    -o-animation-delay          : $delay;
    -o-animation-iteration-count: $iteration;
    -o-animation-direction      : $direction;
    -o-animation-fill-mode      : $fill-mode;
    -o-animation-play-state     : $play-state;
  }

  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin rotateMixin($angle) {
  -webkit-transform: rotate($angle);
  -moz-transform: rotate($angle);
  -ms-transform: rotate($angle);
  -o-transform: rotate($angle);
  transform: rotate($angle);
}

@mixin transformMixin($properties) {
  -webkit-transform: $properties;
  -moz-transform: $properties;
  -ms-transform: $properties;
  -o-transform: $properties;
  transform: $properties;
}

@mixin transitionMixin($properties) {
  -webkit-transition: $properties;
  -moz-transition: $properties;
  -ms-transition: $properties;
  -o-transition: $properties;
  transition: $properties;
}