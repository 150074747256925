@include animationMixin(spin, 2s, infinite, linear) {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  position        : fixed;
  background-color: var(--customVar-bg-black, #0d0421);
  top             : 0;
  left            : 0;
  right           : 0;
  bottom          : 0;
  z-index         : 9991;

  .preloader__circle {
    display          : block;
    position         : relative;
    left             : 50%;
    top              : 50%;
    width            : 150px;
    height           : 150px;
    margin           : -75px 0 0 -75px;
    border-radius    : 50%;
    border           : 3px solid transparent;
    border-top-color : var(--customVar-base, #ffa065);
    -webkit-animation: spin 2s linear infinite;
    animation        : spin 2s linear infinite;
    
    &:before {
      content          : "";
      position         : absolute;
      top              : 5px;
      left             : 5px;
      right            : 5px;
      bottom           : 5px;
      border-radius    : 50%;
      border           : 3px solid transparent;
      border-top-color : var(--hostup-primary, #ff5a74);
      -webkit-animation: spin 3s linear infinite;
      animation        : spin 3s linear infinite;
    }

    &:after {
      content          : "";
      position         : absolute;
      top              : 15px;
      left             : 15px;
      right            : 15px;
      bottom           : 15px;
      border-radius    : 50%;
      border           : 3px solid transparent;
      border-top-color : #ffffff;
      -webkit-animation: spin 1.5s linear infinite;
      animation        : spin 1.5s linear infinite;
    }
  }
}
