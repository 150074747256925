/**********************
*  Section Component  *
**********************/
.custom-section {
  position        : relative;
  display         : block;
  overflow        : hidden;
  padding         : 30px 0;
  background-color: #fafbff;
  z-index         : 1;

  &.gray-bg {
    background-color: var(--bs-gray-200, #e9ecef);
  }

  &.gray-bg2 {
    background-color: #f5f7ff;

    .bg__img {
      position           : absolute;
      top                : 0;
      left               : 0;
      right              : 0;
      bottom             : 0;
      background-image   : url(../../images/background/sectionGray.jpg);
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
      mix-blend-mode     : multiply;
      opacity            : 0.08;
      z-index            : -1;
    }
  }

  &.gray-bg3 {
    background-color: #f5f7ff;

    .bg__img {
      position           : absolute;
      top                : 0;
      left               : 0;
      right              : 0;
      bottom             : 0;
      background-image   : url(../../images/background/sectionGray3.jpg);
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
      z-index            : -1;
    }
  }
  &.gray-bg4 {
    // background-color: #f5f7ff;
    background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);

    .bg__img {
      position           : absolute;
      top                : 0;
      left               : 0;
      right              : 0;
      bottom             : 0;
      background-image   : url(../../images/background/sectionGray4.jpg);
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
      z-index            : -1;
    }
  }

  &.striped-bg {
    background-image   : url(../../images/background/striped.jpg);
    background-repeat  : no-repeat;
    background-position: center;
    background-size    : cover;
  }

  &.gray-animated-bg {
    background-color: #f5f7ff;

    .shape-1 {
      position: absolute;
      top     : 50px;
      right   : 485px;

      img {
        width: auto;
      }
    }

    .shape-2 {
      position      : absolute;
      top           : 0;
      right         : 0;
      mix-blend-mode: multiply;

      img {
        width: auto;
      }
    }

    .shape-3 {
      position: absolute;
      top     : 0;
      right   : 0;

      img {
        width: auto;
      }
    }

    .shape-4 {
      position: absolute;
      top     : 0;
      right   : 288px;

      img {
        width: auto;
      }
    }

    .shape-5 {
      position: absolute;
      top     : 0;
      right   : 270px;

      img {
        width: auto;
      }
    }

    .shape-6 {
      position: absolute;
      top     : 30px;
      right   : 0px;

      img {
        width: auto;
      }
    }

    .shape-7 {
      position: absolute;
      top     : 120px;
      right   : 290px;

      img {
        width: auto;
      }
    }

    .shape-8 {
      position: absolute;
      top     : 285px;
      right   : 45px;

      img {
        width: auto;
      }
    }

    .shape-9 {
      position: absolute;
      top     : 185px;
      right   : 425px;

      img {
        width: auto;
      }
    }

    .shape-10 {
      position: absolute;
      top     : 295px;
      right   : 275px;

      img {
        width: auto;
      }
    }

    .shape-11 {
      position: absolute;
      bottom  : 350px;
      left    : 0px;

      img {
        width: auto;
      }
    }

    .shape-12 {
      position: absolute;
      bottom  : 450px;
      left    : 30px;
      z-index : 2;

      img {
        width: auto;
      }
    }

    .shape-13 {
      position: absolute;
      bottom  : 100px;
      left    : 465px;
      z-index : 1;

      img {
        width: auto;
      }
    }

    .shape-14 {
      position: absolute;
      bottom  : 375px;
      left    : 210px;

      img {
        width: auto;
      }
    }

    .shape-15 {
      position: absolute;
      bottom  : 0px;
      left    : 0px;

      img {
        width: auto;
      }
    }

    .shape-16 {
      position: absolute;
      bottom  : 82px;
      left    : 0px;

      img {
        width: auto;
      }
    }

    .shape-17 {
      position: absolute;
      bottom  : 100px;
      left    : 0px;

      img {
        width: auto;
      }
    }

    .shape-18 {
      position: absolute;
      bottom  : 100px;
      left    : 77px;

      img {
        width: auto;
      }
    }

    .shape-19 {
      position: absolute;
      bottom  : 0px;
      left    : 40px;

      img {
        width: auto;
      }
    }

    .shape-20 {
      position: absolute;
      bottom  : 0px;
      left    : 167px;

      img {
        width: auto;
      }
    }

    .shape-21 {
      position: absolute;
      bottom  : 185px;
      left    : 290px;

      img {
        width: auto;
      }
    }

    .shape-22 {
      position: absolute;
      top     : 116px;
      right   : 30px;
      z-index : 2;

      img {
        width: auto;
      }
    }
  }

  &.gray-animated-2-bg {
    background-color: #f5f7ff;

    .shape-1 {
      position: absolute;
      top     : -193px;
      left    : -10px;
      z-index : -1;

      img {
        width: auto;
      }
    }
  }

  &.blue-bg {
    position  : relative;
    display   : block;
    padding   : 128px 0 110px;
    margin-top: -260px;
    z-index   : 2;

    .bg__img {
      position           : absolute;
      top                : 0;
      left               : 0;
      right              : 0;
      bottom             : 0;
      background-image   : url(../../images/background/sectionBlue2.png);
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
      z-index            : -1;
    }
  }

  &.blue-animated-bg {
    background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);

    .bg__img {
      position           : absolute;
      top                : 0;
      left               : 0;
      right              : 0;
      bottom             : -30px;
      background-image   : url(../../images/background/sectionBlue.png);
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
      z-index            : -1;
    }
  }

  &.blue-animated-2-bg {
    background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);

    .shape-1 {
      position: absolute;
      top     : 0;
      left    : 0;
      z-index : -1;

      img {
        width: auto;
      }
    }

    .shape-2 {
      position: absolute;
      bottom  : 0;
      right   : 0;
      z-index : -1;

      img {
        width: auto;
      }
    }

  }

  &.blue-animated-3-bg {
    background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);

    .shape-1 {
      position           : absolute;
      top                : 0;
      left               : 0;
      right              : 0;
      bottom             : -30px;
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
      z-index            : -1;
    }
  }

  &.blue-animated-4-bg {
    background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);

    .shape-1 {
      position: absolute;
      top     : 0;
      left    : 100px;
      z-index : -1;

      img {
        width: auto;
      }
    }

    .shape-2 {
      position: absolute;
      bottom  : 0;
      right   : -30px;
      z-index : -1;

      img {
        width: auto;
      }
    }

    .shape-3 {
      position: absolute;
      bottom  : 235px;
      right   : 114px;
      z-index : -1;

      img {
        width: auto;
      }
    }

    .shape-4 {
      position: absolute;
      bottom  : 0;
      left    : 323px;
      z-index : -1;

      img {
        width: auto;
      }
    }

    .shape-5 {
      position: absolute;
      top     : 0;
      right   : 0;
      z-index : -1;

      img {
        width: auto;
      }
    }
  }

  &.blue-animated-5-bg {
    background: linear-gradient(286.25deg, #436cff -20.47%, #000b3c 98.18%);

    .shape-1 {
      position: absolute;
      top     : 0;
      right   : 0;
      z-index : -1;
  
      img {
        width: auto;
      }
    }
  
    .shape-2 {
      position: absolute;
      bottom  : 40px;
      right   : 0;
      z-index : -1;
  
      img {
        width: auto;
      }
    }
  
    .shape-3 {
      position: absolute;
      bottom  : 0;
      left    : 0;
      z-index : -1;
  
      img {
        width: auto;
      }
    }
  }

  &.blue-animated-6-bg {
    background: linear-gradient(90deg, #436cff -20.47%, #000b3c 98.18%);

    .bg__img {
      position           : absolute;
      top                : 0;
      left               : 0;
      right              : 0;
      bottom             : -30px;
      background-image   : url(../../images/background/sectionBlue6.png);
      background-repeat  : no-repeat;
      background-position: center;
      background-size    : cover;
      z-index            : -1;
    }
  }

}