.alertymes {
  float: left;
  width: 100%;
  padding: 22px;
  border-radius: 5px;
  color: #f1f1f1;
  border: 1px solid #fa6767;
  background-color: rgba(250, 103, 103, 1);
  // background-color: $red-100;
  -webkit-box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
  -moz-box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
  box-shadow: 1px 1px 1px 0px rgba(50, 50, 50, 0.1);
}

.alertymes i {
  margin-right: 10px;
}
